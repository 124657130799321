<template>
  <modal
    class="success-confirmation"
    :active="getModalPasswordConfirmation"
    :show-close-button="false"
  >
    <q-text center class="banner-img">
      <img :src="Image" width="100%" alt="thankyou-image" title="Thank You" />
    </q-text>
    <q-padder v-if="getModalPasswordConfirmation" vertical="md" horizontal="md">
      <q-text tag="div">
        <q-text variant="ui-small" ink="label" bold center>{{
          modalGreetings
        }}</q-text>
        <q-spacer top="xs" />
        <q-text variant="ui-small" ink="gray-warm" center>{{
          content.QoalaOnlineChangePassword.homeInfoPopupH1Body
        }}</q-text>
        <q-spacer top="lg" />
        <q-button class="register-button" @click="hideModal">OK</q-button>
        <q-spacer top="md" />
      </q-text>
    </q-padder>
  </modal>
</template>

<script>
import {
  Button as QButton,
  Spacer as QSpacer,
  Padder as QPadder,
  Text as QText,
  Modal,
} from '@qoala/ui';
import { mapGetters } from 'vuex';

const Image = 'https://assets.qoala.app/icons/Secured.png';

export default {
  name: 'ToolMessage',
  components: {
    QText,
    QPadder,
    Modal,
    QButton,
    QSpacer,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      Image,
    };
  },
  computed: {
    ...mapGetters({
      getUserData: 'user/getUserData',
      getModalPasswordConfirmation: 'general/getModalPasswordConfirmation',
    }),
    modalGreetings() {
      let greetings = this.content.QoalaOnlineChangePassword.homeInfoPopupH1;
      return greetings.replace('@userName', this.getUserData.fullName);
    },
  },
  methods: {
    hideModal() {
      this.$store.dispatch('general/setModalPasswordConfirmation', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.success-confirmation {
  ::v-deep .modal-content {
    max-height: 75vh !important;
    padding: 0px;
    border-radius: 8px;
    max-width: 480px;
    margin: 0 16px;
  }
  .voucher-container {
    width: 100%;
    height: 200px;
    background: #ff903c;
  }
  .register-button {
    border-radius: 8px !important;
    background-image: linear-gradient(to bottom, #ff9738, #ff8743);
  }
  .banner-img {
    background: #f6f9ff;
    img {
      max-width: 180px;
    }
  }
}
</style>
